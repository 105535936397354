//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
//import { useState } from 'react'
//import {useEffect}  from "react";
//import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
//import Button from 'react-bootstrap/Button';
//import { FaPlus, FaBan } from 'react-icons/fa';
//import styled from 'styled-components'
//import { NavLink } from "react-router-dom";
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
import {theme} from '../../styles/theme'
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



export default function LimitesPoints({friend, classNameProp=""}) {
	//console.log("friend", friend)

	//let pointsDonnes = useSelector((state) => state.app.notes)
	//const scoreTotal = appRecues.filter(n=> n.userReceveur.id===friend.id ).reduce((partial_sum, nte) => partial_sum + nte.points,0);
	let arrPoints = []
	for (let i = 1; i <= friend.limitePoints; i++) {
      	arrPoints.push(<div className={"cerclePoint " + ( (i>friend.pointsDonnesAujourdhui) ? " unused " : " used ") }> </div>)
    }


    return (
        <StyledLimitesPoints
				className={classNameProp}
				title={`${friend.username} peut donner ou enlever ${friend.limitePoints} points par jour. Note max: ${friend.limitePointsPlus}. Min: -${friend.limitePointsMoins} `}
			>
				{/*
				{friend.pointsDonnesAujourdhui}/{friend.limitePoints}
				....
				*/}
			{arrPoints}<br />
			+{friend.limitePointsPlus}/-{friend.limitePointsMoins}
        </StyledLimitesPoints>
    )
}


//█████████████████████████████████████████████████████████████ StyledComponents

const StyledLimitesPoints = styled.span`

	padding:2px;
	/*border: 1px solid ${theme.colors.c1};*/
	font-size:0.6em;
	text-align: center;
	color:${theme.colors.c1};

	.cerclePoint{
		border: 1px solid ${theme.colors.c1};
		display: inline-block;
		width: 10px;
		height: 10px;
		margin-right:1px;
  		border-radius: 25px;
	}

	.unused{
		/*background-color: green;*/
	}
	.used{
		background-color: ${theme.colors.c1};
	}
	/*
	margin-bottom:5px;
	text-decoration:none;
	color:black;

	&:hover{
		background-color:pink;
	}
	.place{

	}
	.pseudo{
		color: ${theme.colors.c1};
		font-size:1.2em;
		font-weight:bold;
	}
	.score{

	}
	*/
`;
