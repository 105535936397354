
//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
//import { useState } from 'react'
//import {useEffect}  from "react";
//import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaBan } from 'react-icons/fa';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//import NotesListe from '../notes/NotesListe'
//■■■■■■■■■■■■■■■■■■■■ STYLES
import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



export default function Mindmap() {


	const colorNames=['C1l5']



//	console.log(app)
    return (
        <StyledPage className="app-page row col-12 pageFriendShow">



            <div className="page-header row col-12">
				<div className="page-title col-12">Mindmap</div>
            </div>






            <div className="page-content page-content-transparantXXXXXXXX row col-12">

				<div className="row col-12 pb-3">
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1d5, color:'white' }}	>c1d5</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1d4, color:'white' }}	>c1d4</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1d3, color:'white' }}	>c1d3</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1d2, color:'white' }}	>c1d2</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1d1, color:'white' }}	>c1d1</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1,  color:'white' }}	>c1</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1l1}}	>c1l1</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1l2}}	>c1l2</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1l3}}	>c1l3</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1l4}}	>c1l4</div>
					<div className="col-1 p-1" style={{backgroundColor:theme.colors.c1l5}}	>c1l5</div>
				</div>

				<div className="row col-12">
					<div class="col-1 p-1 bg-primary text-white">primary</div>
					<div class="col-1 p-1 bg-secondary text-white">secondary</div>
					<div class="col-1 p-1 bg-success text-white">success</div>
					<div class="col-1 p-1 bg-danger text-white">danger</div>
					<div class="col-1 p-1 bg-warning text-dark">warning</div>
					<div class="col-1 p-1 bg-info text-dark">info</div>
					<div class="col-1 p-1 bg-light text-dark">light</div>
					<div class="col-1 p-1 bg-dark text-white">dark</div>
					<div class="col-1 p-1 bg-body text-dark">body</div>
					<div class="col-1 p-1 bg-white text-dark">white</div>
					<div class="col-1 p-1 bg-transparent text-dark">transparent</div>
				</div>


	            <StyledWebPage >
					<StyledTitre>Home</StyledTitre>

					<StyledFetch>
						<li>state.saison = *<b>selected saison</b>*</li>
						<li>state.friends = *<b>liste des friends actifs sur la saison (avec score total)</b>*</li>
						<li>state.notes = *<b>last notes</b>*</li>
					</StyledFetch>

					<StyledComponent>
						<StyledTitre>ClassementListe (ou FriendsListe)</StyledTitre>
						<StyledProps>
							<li>friends = *<b>sorted friends by total</b>*</li>
						</StyledProps>
						<ul>
							<li>sortable (classement ou alphabetique)</li>
						</ul>

						<StyledMapListe>
							<StyledTitre>friends.map =></StyledTitre>
							<StyledComponent>
								<StyledTitre>ClassementItem (ou FriendItem)</StyledTitre>
								<StyledProps>
									<li>friend</li>
								</StyledProps>
							</StyledComponent>
						</StyledMapListe>



					</StyledComponent>

					<StyledComponent>
						<StyledTitre>NotesListe</StyledTitre>
						<StyledProps>
							<li>titre = "<b>Dernières notes:</b>"</li>
							<li>notes = *<b>last notes</b>*</li>
						</StyledProps>


						<StyledMapListe>
							<StyledTitre>notes.map =></StyledTitre>
							<StyledComponent>
								<StyledTitre>NoteItem</StyledTitre>
								<StyledProps>
									<li>note</li>
								</StyledProps>
							</StyledComponent>
							<ul>
								<li>Infinite scroll</li>
							</ul>
						</StyledMapListe>


					</StyledComponent>

	            </StyledWebPage>




	            <StyledWebPage >
					<StyledTitre>FriendShow</StyledTitre>

					<StyledFetch>
						<li>state.saison = *<b>selected saison</b>*</li>
						<li>state.friend = *<b>match friend id</b>*</li>
						<li>state.notes = *<b>notes reçues ou données par ce friend</b>*</li>
					</StyledFetch>

					<StyledComponent>
						<StyledTitre>FriendInfo</StyledTitre>
						<StyledProps>
							<li>friend = state.friend</li>
						</StyledProps>
					</StyledComponent>


					<StyledComponent>
						<StyledTitre>NoteForm</StyledTitre>
						<StyledProps>
							<li>note</li>
						</StyledProps>
					</StyledComponent>



					<StyledComponent>
						<StyledTitre>NotesListe</StyledTitre>
						<StyledProps>
							<li>titre</li>
							<li>notes</li>
						</StyledProps>


						<StyledMapListe>
							<StyledTitre>notes.map =></StyledTitre>
							<StyledComponent>
								<StyledTitre>NoteItem</StyledTitre>
								<StyledProps>
									<li>note</li>
								</StyledProps>
							</StyledComponent>
							<ul>
								<li>Infinite scroll</li>
							</ul>
						</StyledMapListe>
					</StyledComponent>



	            </StyledWebPage>



			</div>

 		</StyledPage>

    )



}





const StyledProps = styled.ul`
	padding:5px;
	margin-bottom:10px;
	color:green;
	border: 1px solid green;
	&:before {
		content: "props:";
		font-weight:bold;
		text-decoration: underline;
	}
	li{
		margin-left: 20px;
		font-size:0.8em;
	}
`;



const StyledFetch = styled.ul`
	padding:5px;
	margin-bottom:10px;
	color:gray;
	border: 1px solid green;
	background-color:white;
	&:before {
		content: "fetched:";
		font-weight:bold;
		text-decoration: underline;
	}
	li{
		margin-left: 20px;
		font-size:0.8em;
	}
`;





const StyledTitre = styled.h1`
	padding:5px 10px 5px 10px;
	margin-bottom:10px;
	border-radius: 10px;
	display:inline-block;
	font-size: 1.2em;
`;





const StyledWebPage = styled.div`
  	background-color: blue;
	/*border: 4px solid blue;*/
	margin: 8px;
	padding: 10px;
	display: inline-block;
	&>h1{
		&:before {content: "_page/";}
		background-color:white;
		color:blue;
	}
`;



const StyledComponent = styled.div`
  	background-color: white;
	border: 2px solid red;
	margin-bottom: 10px;
	padding: 5px;
	&>h1{
		&:before {content: "<";}
		&:after {content: " />";}
		background-color:red;
		color:white;
		font-size: 1.0em;
	}
`;



const StyledMapListe = styled.div`
  	background-color: orange;
	border: 2px solid red;
	font-size: 0.8em;
	/*margin: 10px;*/
	padding: 5px;
	&>h1{
		background-color:white;
		color:darkOrange;
		font-size: 1.0em;
	}
`;
