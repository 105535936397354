// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import { BrowserRouter as Router,  Switch,  Route} from "react-router-dom";
//import { useEffect } from 'react';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
import {apiGlobalFetch} from '../api/api'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import ZxAppGlobalContainer 	from './_composants/ZxAppGlobalContainer'
import Login 		from './login/Login'
import UserIndex 	from './user/UserIndex'
//import Loading 	from './_nav/Loading
import Mindmap 		from './_pages/Mindmap'
import Home 		from './_pages/Home'
import FriendShow 	from './_pages/FriendShow'
// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/App.scss';


// █████████████████████████████████████████████████████████████████████████████

export default function App() {



	let app = useSelector((state) => state.app)

	const dispatch = useDispatch();
	const [appIsFetching, setAppIsFetching] = useState(false)
	const [appFetchingError, setAppFetchingError] = useState(null)


	function globalFetch(options){
	  	console.log('██ globalFetch ██');
		if(!app.user && !localStorage.getItem("userToken")){
			console.log("ANONYMOUS:")
			dispatch({ type: "SET_USER_STATUS", payload: "ANONYMOUS" })
			setAppIsFetching(false)
			return
		}
		//console.log('██ globalFetch - exec ██');
		setAppIsFetching(true)
		//console.log(localStorage.getItem("userToken"))
		//console.log(options)

		//console.log("ccccccccccccccccccccc")
		apiGlobalFetch(options)
		.then(
			(result) => {
				console.log(result)

				//console.log("dddddddddddddddddddd")
				if(result.apixError){
					if(result.apixError ===  "USER_NOT_FOUND"){
						console.log("apixError : USER_NOT_FOUND : localStorage.removeItem(userToken)+++")
						localStorage.removeItem("userToken")
						dispatch({ type: "SET_USER_STATUS", payload: "ANONYMOUS" })
					}
					else{
						console.log("apixError : Unknown")
						dispatch({ type: "SET_USER_STATUS", payload: "ANONYMOUS" })
					}
				}
				else{
					dispatch({ type: "DISPATCH_GLOBAL_FETCH", payload: result })
				}

				setAppIsFetching(false)


	   			//console.groupEnd();
			},
			(error) => {
				setAppIsFetching(false)
				setAppFetchingError("apiMesEtablissements-unknown error-check log")
				console.log(error)
				console.log("error")
				console.log("eeeeeeeeeeeeeeeeee")
	   			//console.groupEnd();
			}
		)

	}



	// ██████████████████   return   ██████████████████



  	return (
		<>
		{(app.userStatus==="ANONYMOUS" || app.userStatus==="LOGGING") ?
			<Login />
		:
	    	<Router>
				<ZxAppGlobalContainer>
					<RenderSwitchRoute />
				</ZxAppGlobalContainer>
		    </Router>
		}
		{/*
		<div className="debug">
			rUser.userStatus: <b>{ rUser.userStatus }</b><br />
			localStorage.getItem("userToken"): <b>{ localStorage.getItem("userToken") }</b><br />
		</div>
		*/}
		</>
  	)


	function RenderSwitchRoute(){
		return(
	        <Switch>



				<Route exact path="/admin/users" render={(props) => ( <UserIndex
						{...props}
						globalFetch={globalFetch}
						appIsFetching={appIsFetching}
						appFetchingError={appFetchingError}
					/>	)}
				/>
				<Route exact path="/friend/:id" render={(props) => ( <FriendShow
						{...props}
						globalFetch={globalFetch}
						appIsFetching={appIsFetching}
						appFetchingError={appFetchingError}
					/>	)}
				/>
				<Route exact path="/mindmap" render={(props) => ( <Mindmap
						{...props}
						globalFetch={globalFetch}
						appIsFetching={appIsFetching}
						appFetchingError={appFetchingError}
					/>	)}
				/>
				<Route path="/" render={(props) => ( <Home
						{...props}
						globalFetch={globalFetch}
						appIsFetching={appIsFetching}
						appFetchingError={appFetchingError}
					/>	)}


				/>




	        </Switch>
		)
	}



}
