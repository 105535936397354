//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
//import { useState } from 'react'
//import {useEffect}  from "react";
//import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';
//import { FaPlus, FaBan } from 'react-icons/fa';
//import styled from 'styled-components'
import { FaSortAlphaDown, FaSortAmountDown } from 'react-icons/fa';
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//import FriendItem from './FriendsListeFilter'
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



export default function FriendsListeFilter({sortBy, setSortBy}) {
    return (
        <div className="mb-3 float-end">
			Trier par
			&nbsp;
			<Button
				className="btn-sm"
				variant={sortBy==="PSEUDO" ? "primary" : "outline-primary"}
				onClick={()=>setSortBy("PSEUDO")}
			>
				<FaSortAlphaDown />
			</Button>
			&nbsp;&nbsp;
			<Button
				className="btn-sm"
				variant={sortBy!=="PSEUDO" ? "primary" : "outline-primary"}
				onClick={()=>setSortBy("SCORE")}
			>
				<FaSortAmountDown />
			</Button>
        </div>
    )
}
