//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS :
import {theme} from '../../styles/theme'
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//■■■■■■■■■■■■■■■■■■■■ CSS
//██████████████████████████████████████████████████████████████████████████████



export default function ZxModal({children, styles }) {
    return (
		<div className="zxModalContainer" >
			<StyledOpaqueBackground />
			<StyledModal style={styles}>
				{children}
			</StyledModal>
		</div>
    )
}



const StyledOpaqueBackground = styled.div`
  	background-color: ${theme.colors.c1};
	opacity: 0.6;
	position: fixed;
	top: 0;
	left:0;
	height: 100vh;
	width: 100%;
	z-index: 1000;
`;

const StyledModal = styled.div`
	background-color: white;
	border: 2px solid ${theme.colors.c1d3};
	z-index: 2000;
	position: fixed;
	margin: auto; /* Will not center vertically and won't work in IE6/7. */
	left: 0;
	right: 0;
	/*top: 0;*/
	/*bottom:0;*/
	max-width: 100%;
	max-height: 90%;
	overflow-y: auto;
	padding: 10px;
`;
