import React from "react";
//import { NavLink } from "react-router-dom";
//import { Link } from "react-scroll";
//import { LinkContainer } from "react-router-bootstrap";
import { Dropdown } from "react-bootstrap";
import {useEffect, useState}  from "react";
import { useSelector,  useDispatch  } from "react-redux";
import styled from 'styled-components'
//import { FaUserFriends, FaHome, FaRoute, FaSignOutAlt, FaMap} from 'react-icons/fa';





// █████████████████████████████████████████████████████████████████████████████

export default function NavSaisons({ globalFetch }) {
	//const app = useSelector((state) => state.app)
	const app = useSelector((state) => state.app)
	const dispatch = useDispatch();
	//console.log(app.user)
	const [saisonX, setSaisonX] = useState(app.saison)

	function changeSaisonClick(saison){
		//alert(saison.nom)
		setSaisonX(saison)


	}
    useEffect(() => {
		//console.log("useEffect")
        if (	(app.saison.id !== saisonX.id)	 ){
			//console.log("useEffect-globalFetch")
			globalFetch({
				fetchUser: true, //(app.user ? false : true),
				fetchUsers: true,
				fetchNotes: true,
				fetchSaisons: true,
				fetchSaison: true,
				saisonId: saisonX.id,	// null=> current, (0 => toute) // ?????
			})
    	}
    }, [saisonX])



	return (
		<StyledDropdown className='float-start'>
		  	<Dropdown.Toggle variant="primary" id="dropdown-basic">
				{app.saison &&
					<>
						Saison: <b>{app.saison.nom}</b><br />
						<small className="dates">du {app.saison.dateDebut} au {app.saison.dateFin}  </small>
					</>
				}
		  	</Dropdown.Toggle>
		  	<Dropdown.Menu>
				{app.saisons && app.saisons.map(saison =>(
					<Dropdown.Item
						href="#/action-1"
						key={'saison'+saison.id}
						active={saison.id === app.saison.id ? true : false}
						onClick={()=>changeSaisonClick(saison)}
					>
						{saison.nom}
					</Dropdown.Item>
				))}
		  	</Dropdown.Menu>
		</StyledDropdown>
	)

}




//██████████████████████████████████████████████████████████████StyledComponents

const StyledDropdown = styled(Dropdown)`
	.dates{
		font-size: 0.7em;
	}
`;

const StyledDropdownItem = styled(Dropdown.Item)`

`;
