//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
//import { useState } from 'react'
//import {useEffect}  from "react";
//import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
//import Button from 'react-bootstrap/Button';
//import { FaPlus, FaBan } from 'react-icons/fa';
//import styled from 'styled-components'
import { NavLink } from "react-router-dom";
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
import {theme} from '../../styles/theme'
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import LimitesPoints from './LimitesPoints'
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



export default function FriendItem({friend}) {
	//console.log("friend", friend)

	//let pointsDonnes = useSelector((state) => state.app.notes)
	//const scoreTotal = appRecues.filter(n=> n.userReceveur.id===friend.id ).reduce((partial_sum, nte) => partial_sum + nte.points,0);

    return (
        <StyledFriendItem to={"/friend/"+friend.id}	className=" col-12"  >
			<span className="pseudo">{friend.username}</span>
			<span className="score float-end ms-3">{friend.scoreTotal} pts</span>
			<LimitesPoints friend={friend}  classNameProp="float-end"/>
        </StyledFriendItem>
    )
}


//█████████████████████████████████████████████████████████████ StyledComponents

const StyledFriendItem = styled(NavLink)`
	padding:10px;
	border: 1px solid ${theme.colors.c1};
	margin-bottom:5px;
	text-decoration:none;
	color:black;

	&:hover{
		background-color:pink;
	}
	.place{

	}
	.pseudo{
		color: ${theme.colors.c1};
		font-size:1.2em;
		font-weight:bold;
	}
	.score{
		font-weight: bold;
	}
`;
