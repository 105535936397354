//█████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■	LIBS
import React from "react";
//import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■	HELPERS
import {theme} from '../../styles/theme'
//■■■■■■■■■■■■■■■■■■■■	COMPONENTS
//import Loading from '../_nav/Loading'
import NavHeader 	from '../_nav/NavHeader'
//■■■■■■■■■■■■■■■■■■■■	CSS
//█████████████████████████████████████████████████████████████████████████████


//export const FormContext = React.createContext({	form: {}	});


export default function ZxAppGlobalContainer({children})  {


  	return (
    	<StyledGlobalContainer
			fluid="sm"
			id="appGlobalContainer"
		>

			<NavHeader />
	        {children}
    	</StyledGlobalContainer>
  	)

}


const StyledGlobalContainer = styled(Container)`
  	background-color: ${theme.colors.c1l5};;
	padding: 0px;
	margin-bottom:50px;
	/*width: 100%;*/
`;
