/*
*	FriendShow
*	- Page d'un utilisateur :
*		- Affichage de son score Total (et de ses bonus: TODO)
*		- Affichage de ses dernières notes (données et/ou reçues)
*		- formulaire pour lui donner une note
*
*/
//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
import { useState } from 'react'
import {useEffect}  from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';
import { FaArrowCircleLeft, FaKey} from 'react-icons/fa';
import styled from 'styled-components'
import { NavLink } from "react-router-dom";
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import NotesListe from '../notes/NotesListe'
import NoteForm from '../notes/NoteForm'
import FormPassword from '../user/FormPassword'
import NavSaisons 			from '../_nav/NavSaisons'
//■■■■■■■■■■■■■■■■■■■■ STYLES
import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



const StyledWhiteZone = styled.div`
  	background-color: white;
	border: 1px solid black;
	margin: 5px;
	padding: 10px;
`;


export default function FriendShow({match, globalFetch}) {

	const paramId = (match && match.params && match.params.id) ? match.params.id : "XX"
	const friendId = isNaN(parseInt(paramId)) ? 0 : parseInt(paramId)

	const app = useSelector((state) => state.app)

	const [formNoterIsOpen, setFormNoterIsOpen]=useState(false)
	const [formPasswordIsOpen, setFormPasswordIsOpen]=useState(false)
	const [formPassword, setFormPassword]=useState(null)


    useEffect(() => {
        if ( (app.user===null)	 ||	(app.users===null)		 ||	(app.notes===null)	 ){
			globalFetch({
				fetchUser: true, //(app.user ? false : true),
				fetchUsers: true,	//!!!!!!!!!!!!!!!!!!!!!!!!!!!! ON FETCH TOUS LES USERS et toute les notes !!!!!!!!!!!!!!!!!!!!!
				fetchNotes: true,
				fetchSaisons: true,
				fetchSaison: true,
				saisonId: null,	// null=> current, (0 => toute) // ?????
			})
    	}
    }, [])




    if ( (app.user===null)	 ||	(app.users===null)		 ||	(app.notes===null)	 ||	(app.saison===null)	 ||	(app.saisons===null)	 ){
		return <>Chargement</>
	}


	const friend = app.users.filter(f=>f.id===friendId )[0]									// !!!!!!!!!!!!!!!!!!!!!!!!!! TEMP !!!!!!!!!!!
	const notes = app.notes.filter(n=> n.userDonneur.id===friendId || n.userReceveur.id===friendId )		// !!!!!!!!!!!!!!!!!!!!!!!!!! TEMP !!!!!!!!!!!

	const cestMoi = app.user && (friendId === app.user.id)



	function editPasswordClick(){
		 setFormPassword({
			oldPassword:"",
			newPassword1:"",
			newPassword2:"",
		})
		setFormPasswordIsOpen(!formPasswordIsOpen)
	}


    return (
        <StyledPage className="row col-12 pageFriendShow">

			{formPassword  &&
				<FormPassword
					formInitialValues={formPassword}
					handleCloseForm={()=>setFormPassword(null)}
					friend={friend}
				/>
			}

            <StyledPageHeader className="col-12">


				<NavSaisons  globalFetch={globalFetch} />

				<NavLink to={"/"} className="pseudo" title="Retour au classement" >
					<FaArrowCircleLeft /> &nbsp;&nbsp; {friend.username}
				</NavLink>

				<span className="float-end score">
					Score: <br />
					<b>{friend.scoreTotal}</b>
				</span>


				{formPassword === null && app.user.id=== 3 &&
					<Button
					 	className="float-end btn-sm me-3"
						onClick={()=>editPasswordClick()}
					>
						<FaKey />
					</Button>
				}

            </StyledPageHeader>






            <div  className="page-content page-content-transparant row col-12">



				{!cestMoi &&
		            <StyledWhiteZone className="col-12 text-center">

						<Button
							variant={formNoterIsOpen ? "outline-primary" : "primary" }
							onClick={()=>setFormNoterIsOpen(!formNoterIsOpen)}
						>
							{formNoterIsOpen ? <>x Fermer</> : `Noter ${friend.username}` }

						</Button>

						{formNoterIsOpen &&
							<NoteForm
								friend={friend}
								handleCloseForm={()=>setFormNoterIsOpen(false)}
								/*connectedUser={app.users.filter(u => u.id=== app.user.id)}*/
								connectedUser={app.user}
							/>

						}
		            </StyledWhiteZone>
				}


				<StyledWhiteZone  className="col-12">
					<NotesListe
						titre={"Les notes de " + friend.username}
						notes={notes}
					/>
				</StyledWhiteZone>



			</div>

 		</StyledPage>

    )



}





const StyledPageHeader = styled.div`
  	/*border: 1px solid red;*/
	/* background-color:yellow;*/
	color:${theme.colors.c1d3};
	text-align:center;
	margin-bottom:20px !important;

	.pseudo{
		font-size: 1.6em;
		font-weight: bold;
		color:${theme.colors.c1};
		text-decoration: none;
	}

	.score{
		b{
			font-size:1.4em;
		}
	}

`;
