//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS :
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//■■■■■■■■■■■■■■■■■■■■ CSS
//██████████████████████████████████████████████████████████████████████████████



export const theme = {
	colors:{
		c1l5: '#faf0f7',
		c1l4: '#f0cee4',
		c1l3: '#e19dc9',
		c1l2: '#d26cae',
		c1l1: '#c23b93',

		c1: '#902c6d',

		c1d1: '#822862',
		c1d2: '#732357',
		c1d3: '#651f4d',
		c1d4: '#571b42',
		c1d5: '#481637',
	}

}




export const StyledPage = styled.div`

		padding-top: 10px;
		width: 100%;
  /*	color: ${theme.colors.c1}; */
  /*	background-color: cyan;*/
`;
