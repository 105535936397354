// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import { useState } from "react";
import {useEffect}  from "react";
import { /*useSelector,*/  useDispatch  } from "react-redux";
import Button from 'react-bootstrap/Button';
import styled from 'styled-components'
// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
import { apiNoteCreate} from '../../api/api'
import {theme} from '../../styles/theme'
// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
//import Loading from '../_nav/Loading'
//import ZxModal			from '../_composants/ZxModal'
//import ZxForm			from '../_composants/ZxForm'
//import ZxFormInput		from '../_composants/ZxFormInput'
//import ZxFormHeader		from '../_composants/ZxFormHeader'
//import ZxFormFooter		from '../_composants/ZxFormFooter'
// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
// █████████████████████████████████████████████████████████████████████████████



export default function NoteForm({friend, handleCloseForm, connectedUser}) {

	const [note, setNote] = useState({
		points: 0,
		motif: "",
		userReceveurId: friend.id,
		userDonneurId: connectedUser.id
	})

	const [isLoading, setIsLoading] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);
  	const dispatch = useDispatch();


	const limitePointsToday = connectedUser.limitePoints - connectedUser.pointsDonnesAujourdhui
	const limitePointsPlusToday = Math.min(connectedUser.limitePointsPlus, limitePointsToday)
	const limitePointsMoinsToday = Math.min(connectedUser.limitePointsMoins, limitePointsToday)

	console.log("connectedUser", connectedUser)

	useEffect(() => {
		setFormIsValid(note.points!==0 && note.motif!=="")
    }, [note])

	useEffect(() => {
		// reinitialise le formulaire quand on change d'user
		setNote({
			points: 0,
			motif: "",
			userReceveurId: friend.id,
			userDonneurId: connectedUser.id
		})
    }, [friend, connectedUser])



	function handleMotifChange(event){
  		setNote({...note, motif:event.target.value})
	}
	function handlePointsChange(increment){
		let newPoints = note.points+increment
		newPoints = newPoints > limitePointsPlusToday ? limitePointsPlusToday : newPoints
		newPoints = newPoints < -limitePointsMoinsToday ? -limitePointsMoinsToday : newPoints

  		setNote({...note, points: newPoints})
	}





	function handleSubmitCreate(){
		//alert("Todo : handleSubmitCreate")


		setIsLoading(true)
		apiNoteCreate(note)
			.then(
				(result) => {
					//console.log("result:::::::", result)
					if(result.note){
						dispatch({type:"CREATE_NOTE", payload: result.note})
					}
					handleCloseForm()
					setIsLoading(false)
				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)

	}




    return (
        <StyledForm >

			<div>
				Vous avez donné <b>{connectedUser.pointsDonnesAujourdhui}</b> point{connectedUser.pointsDonnesAujourdhui>0 && "s"} aujourd'hui.
				Il vous en reste <b>{limitePointsToday}</b><br />
				{limitePointsToday === 0 && <>Réessayez demain <br /></>}
				Max: <b>{connectedUser.limitePointsPlus}</b> / Min: <b>{connectedUser.limitePointsMoins}</b>

			</div>


            <Button onClick={()=>handlePointsChange(-1)}>
				-
			</Button>

			<h2 style={{display:"inline-block", marginLeft:"10px", marginRight:"10px"}} >
				{note.points}
			</h2>

	        <Button onClick={()=>handlePointsChange(1)}>
				+
			</Button>

			<label className="col-12 zxFormInput mt-3">
				Motif:
				<textarea
					id="story" key="textarea-motif" name="motif"
					rows="3"
					value={note.motif}
					onChange={(event)=>handleMotifChange(event)}
					className="col-12"
				/>
			</label>

			{isLoading ? <h3>Loading</h3> :
		        <Button onClick={()=>handleSubmitCreate()} variant={formIsValid ? "primary" : "secondary"} disabled={!formIsValid} >
					<>Donner {note.points} point{Math.abs(note.points)>1 && "s"} à {friend.username}</>
				</Button>
			}


        </StyledForm>
    )


}



const StyledForm = styled.form`
  	background-color: white;
	/* background-color:yellow;*/
	margin:auto;
	margin-top:20px;
	max-width: 600px;
	color:${theme.colors.c1d3};

`;
