//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
//import { useState } from 'react'
//import {useEffect}  from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
//import Button from 'react-bootstrap/Button';
//import { FaPlus, FaBan } from 'react-icons/fa';
//import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import NoteItem from './NoteItem'
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



export default function NotesListe({notes, titre=null}) {
	const app = useSelector((state) => state.app)

    return (
        <div className="xxxxxxxxxxxx">

			{titre && <h5>{titre}</h5>}

			{notes.map((nte)=>(
				<NoteItem note={nte} key={"note"+nte.id}/>
			))}

        </div>
    )
}
