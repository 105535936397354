
//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
//import { useState } from 'react'
import { useEffect } from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';
//import { FaPlus, FaBan } from 'react-icons/fa';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import NotesListe 			from '../notes/NotesListe'
import FriendsListe 		from '../friends/FriendsListe'
import NavSaisons 			from '../_nav/NavSaisons'
//■■■■■■■■■■■■■■■■■■■■ STYLES
import {theme, StyledPage} from '../../styles/theme'

//██████████████████████████████████████████████████████████████████████████████


export default function Home({globalFetch}) {



	const app = useSelector((state) => state.app)

    useEffect(() => {
		console.log("useEffect")
        if ( (app.user===null)	 ||	(app.users===null)		 ||	(app.notes===null) ||	(app.saison===null) ||	(app.saisons===null)	 ){
			console.log("useEffect-globalFetch")
			globalFetch({
				fetchUser: true, //(app.user ? false : true),
				fetchUsers: true,
				fetchNotes: true,
				fetchSaisons: true,
				fetchSaison: true,
				saisonId: app.saison ? app.saison.id : null,	// null=> current, (0 => toute) // ?????
			})
    	}
    }, [])




    if ( (app.user===null)	 ||	(app.users===null)		 ||	(app.notes===null)	 ||	(app.saison===null) ||	(app.saisons===null)		 ){
		return <>chargement</>
	}

	/*
	app.users.map((frd)=>(
		frd.scoreTotal =  app.notes.filter(n=> n.userReceveur.id===frd.id ).reduce((partial_sum, nte) => partial_sum + nte.points,0)
	))
	*/
	//	console.log(app)
    return (
        <StyledPage className="app-page row col-12 pageFriendShow">


			<NavSaisons globalFetch={globalFetch} />

            <div className="page-content page-content-transparantXXXXXXX row col-12">

				<StyledWhiteZone  className="row col-12">
					<FriendsListe titre="Classement de la saison" />
				</StyledWhiteZone>

				<StyledWhiteZone  className="row col-12">
					<NotesListe titre="Les notes de la saison:"  notes={app.notes}	/>
				</StyledWhiteZone>



			</div>

 		</StyledPage>

    )



}



//█████████████████████████████████████████████████████████████	StyledComponents


const StyledWhiteZone = styled.div`
  	background-color: white;
	border: 1px solid black;
	margin: 5px;
	padding: 10px;
`;
