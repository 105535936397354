import {apiBaseUrl} from '../../helpers/constants'



export async function apiLogin(username, password){

	let user = null
	let error = null
  	const url = apiBaseUrl + "/login"
	console.log(url)

  	const requestOptions = {
	  	method: 'POST',
	  	headers: {
		  	'Content-Type': 'application/json',
		  	'Accept': 'application/json',
	  	},
	  	body: JSON.stringify({
		  	username: username,
		  	password: password,
	  	})
  	}

  	try{
	  	const response = await fetch(url, requestOptions)
	  	const rep = await response.json()

	  	if(rep.apixError){
		  	error = rep.apixError
	  	}
	  	else if(rep.user){
		  	user = rep.user
	  	}
	  	else{
		  	error = "Erreur de connexion inconnue"
	  	}
  	}
  	catch(err){
	  	error = "Catch: " + err
  	}
  	finally{
		return {
			user: user,
			error: error
		}
  	}


}
