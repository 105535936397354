// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
//import { useState } from 'react'
//import {useEffect}  from "react";
//import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
//import Button from 'react-bootstrap/Button';


// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
//import Loading 		from '../_nav/Loading'
import UserTr 		from './UserTr'
//////////////////import FormUser from './FormUser'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./dp.scss"


// █████████████████████████████████████████████████████████████████████████████
export default function UsersIndex({users, setSelectedUser}) {


	users.sort((a,b) => (a.pseudo.toLowerCase() > b.pseudo.toLowerCase()) ? 1 : ((b.pseudo.toLowerCase() > a.pseudo.toLowerCase()) ? -1 : 0))

//	console.log(app)
    return (
        <table className="tableUsers zxTable">

			<thead>
				<tr className="trCommentaire">
					<th colSpan="5">
						Liste des utilisateurs
					</th>
				</tr>
				<tr className="zxTrColumnsHeader">

   					<th></th>
   					<th>Elève</th>
   					<th className="text-center">Classe</th>
   					<th className="text-center">Régime</th>

					<th>Jours prios</th>
					{/*
				   <th>Lundi</th>
				   <th>Mardi</th>
				   <th>Jeudi</th>
				   <th>Vendredi</th>
				   */}

				   <th>carte</th>
				   <th>Commentaire</th>
				</tr>
			</thead>

			<tbody>

				{ users  && users.map((user, e) => {
					return (
						<UserTr
							key={'user-'+e}
							//setSelectedAppel={setSelectedAppel}
							user={user}
							setSelectedUser={setSelectedUser}
							/>
					)
				}) }
				{/*
				{ appels.map((appel, a) => {
					return	<AppelTr
							key={'appel-'+a}
							setSelectedAppel={setSelectedAppel}
							appel={appel}
					 	/>
				})}
				*/}
			</tbody>
			{/*
			<tfoot>
				<tr>
				  <td colSpan="5">xxxxxxxxxxxxxxxxxxxxxxx</td>
				</tr>
			</tfoot>
			*/}


		</table>

    )





}
