import {apiBaseUrl} from '../helpers/constants'

//██████████████████████████████████	NEW GLOBAL FETCHING		████████████████


export function apiGlobalFetch(options){
	const url = apiBaseUrl + "/global_fetch"

	console.log("options:", options)
	//console.log(options)



	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},

	    body: JSON.stringify({
			fetchUser: 		options.fetchUser 		? options.fetchUser		: false,
			fetchUsers: 	options.fetchUsers 		? options.fetchUsers	: false,
			fetchNotes: 	options.fetchNotes 		? options.fetchNotes	: false,
			fetchSaison: 	options.fetchSaison 	? options.fetchSaison	: false,
			saisonId: 		options.saisonId 		? options.saisonId		: null,
			fetchSaisons: 	options.fetchSaisons 	? options.fetchSaisons	: false,


		})

	};

	return fetch(url, requestOptions).then(res => res.json())

}






//██████████████████████████████████████████████████████████████████████████████


export function apiNoteCreate(note){
	const url = apiBaseUrl + "/note/create"

	//console.log("url:", url)
	//console.log("note:", note)

	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},
	    body: JSON.stringify({
			userDonneurId: note.userDonneurId,
			userReceveurId: note.userReceveurId,
			motif: note.motif,
			points: note.points,
		})
	};


	return fetch(url, requestOptions).then(res => res.json())




}



export function apiEditUserPassword(userId, adminPassword, newPassword){
	const url = apiBaseUrl + "/edit_user_password"

	console.log("userId:", userId)
	console.log("adminPassword:", adminPassword)
	console.log("newPassword:", newPassword)

	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},
	    body: JSON.stringify({
			userId: userId,
			adminPassword: adminPassword,
			newPassword: newPassword
		})
	};


	return fetch(url, requestOptions).then(res => res.json())




}






//██████████████████████████████████████████████████████████████████████████████
