// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import { useState } from "react";
//import {  useSelector,   useDispatch  } from "react-redux";
import Button from 'react-bootstrap/Button';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
//import { apiUserEdit, apiUserCreate, apiUserDelete } from '../../api/cmzApi'


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading from '../_nav/Loading'


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■



// █████████████████████████████████████████████████████████████████████████████



export default function FormUser({user, setUser, etabId}) {


	//const app = useSelector((state) => state.app)
  	//const dispatch = useDispatch();



	const [isLoading, setIsLoading] = useState(false);
	const [deleteErrorMessage, setDeleteErrorMessage] = useState(null)

	//const [id, setId] = useState(user.id)
	const [nom, setNom] = useState(user.nom)
	const [prenom, setPrenom] = useState(user.prenom)
	const [classe, setClasse] = useState(user.classe)
	const [regime, setRegime] = useState(user.regime)
	const [prioLundi, setPrioLundi] = useState(user.prioLundi)
	const [prioMardi, setPrioMardi] = useState(user.prioMardi)
	const [prioMercredi, setPrioMercredi] = useState(user.prioMercredi)
	const [prioJeudi, setPrioJeudi] = useState(user.prioJeudi)
	const [prioVendredi, setPrioVendredi] = useState(user.prioVendredi)
	const [commentaire, setCommentaire] = useState(user.commentaire)
	const [actif, setActif] = useState(user.actif)
	const [numeroCarte, setNumeroCarte] = useState(user.numeroCarte)


//	console.log(app.etab)

	function handleSubmitEdit(){

		setIsLoading(true)
		/*
		apiUserEdit(id, nom, prenom, classe, regime, prioLundi, prioMardi, prioMercredi, prioJeudi, prioVendredi, commentaire, actif, numeroCarte)
			.then(
				(result) => {
					//console.log(result)
					if(result.user){
						dispatch({type:"UPDATE_ELEVE", payload: result.user})
					}
					closeForm()
					setIsLoading(false)
				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)
		*/
	}

	function handleSubmitCreate(){

		setIsLoading(true)
		/*
		apiUserCreate(etabId, nom, prenom, classe, regime, prioLundi, prioMardi, prioMercredi, prioJeudi, prioVendredi, commentaire, actif, numeroCarte)
			.then(
				(result) => {
					console.log(result)
					if(result.user){
						dispatch({type:"CREATE_ELEVE", payload: result.user})
					}
					closeForm()
					setIsLoading(false)
				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)
			*/
	}

	function handleDelete(){

	//	alert("Cette fonction est momentanement désactivée")
	//	return null
		if ( ! window.confirm('Voulez-vous vraiment supprimer cet élève ?')){
			return
		}
		/*
		setIsLoading(true)
		apiUserDelete(user.id)
			.then(
				(result) => {
					console.log(result)
					if (result.REPONSE && result.REPONSE==="OK"){
						dispatch({type:"DELETE_ELEVE", payload: user.id})
						setIsLoading(false)
						closeForm()
					}
					else{
						console.log(result)
						alert('error')
						result.detail && setDeleteErrorMessage(result.detail)
					}


				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)
			*/
	}


	function closeForm(){
		setUser(null)
	}



    return (

		<div className="zxModalContainer"  >
			<div className="zxModalOpaqueBackground"></div>

			<form className="row col-12 zxModal" style={{width: "800px"}}>


				<div className="row col-12 formHeader"	>
					<h3>
					{user.id ?
						<>Modifier l'élève : <b>{user.nom + " " + user.prenom}</b></>
						:
						<>Ajouter un élève (ou AED)</>
					}
					</h3>
				</div>

				{user.id !== null &&
					<div className="col-12 header-delete">
						<Button
							className="btn btn-danger btn-xs float-right"
							title="Supprimer cet periode"
							onClick={()=>handleDelete()}
						>
							Supprimer
						</Button>
					</div>
				}

				<div className="row col-12 deleteUserErrorMessage">
					{deleteErrorMessage}
				</div>



				<div className="row col-12 fields">

					<div className="col-6 fields-left">
						<label className="col-12 field">
							Nom
							<input value={nom} name="nom" autoFocus onChange={(event)=>setNom(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							Prénom
							<input value={prenom} name="prenom" onChange={(event)=>setPrenom(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							classe (ou "AED")
							<input value={classe} name="classe" onChange={(event)=>setClasse(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							regime
							<select
								name="regime"
								id="regime"
								onChange={(event)=>setRegime(event.target.value)}
								value={regime}
							>
								<option value="">?</option>
							    <option value="EXT">Externe</option>
							    <option value="DP">Demi-pensionnaire</option>
							</select>
						</label>
						<label className="col-12 field">
							Numero de carte
							<input value={numeroCarte} name="numeroCarte" onChange={(event)=>setNumeroCarte(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							Actif
							[TODO]
						</label>
					</div>

					<div className="col-6 fields-left">
						<label className="col-12 field">
							Prio Lundi
							<input value={prioLundi} name="prioLundi" onChange={(event)=>setPrioLundi(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							Prio Mardi
							<input value={prioMardi} name="prioMardi" onChange={(event)=>setPrioMardi(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							Prio Mercredi
							<input value={prioMercredi} name="prioMercredi" onChange={(event)=>setPrioMercredi(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							Prio Jeudi
							<input value={prioJeudi} name="prioJeudi" onChange={(event)=>setPrioJeudi(event.target.value)}	/>
						</label>
						<label className="col-12 field">
							Prio Vendredi
							<input value={prioVendredi} name="prioVendredi" onChange={(event)=>setPrioVendredi(event.target.value)}	/>
						</label>
					</div>

					<div className="col-12  fields-left">
						<label className="col-12 row field">
							<div className="col-3">Commentaire</div>
							<input value={commentaire}  className="col-9" name="commentaire" onChange={(event)=>setCommentaire(event.target.value)}	/>
						</label>
					</div>

				</div>








				{ isLoading ? (<Loading />) : (
					<div className="col-12 mt-5 zone-boutons">




						{user.id !== null ? (
							<Button
								className="btn btn-success col-4"
								onClick={()=>handleSubmitEdit()}
								tabIndex="0"
							>
								Modifier
							</Button>
						): (
							<Button
								className="btn btn-success col-4"
								onClick={()=>handleSubmitCreate()}
								tabIndex="0"
							>
								Ajouter
							</Button>
						)}

						<Button
							className="btn btn-danger col-4 offset-2"
							onClick={()=>closeForm()}
							tabIndex="0"
							>
							Annuler
						</Button>
					</div>
				)}


			</form>


		</div>
    );
  }
