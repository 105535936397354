const initialState = {
	user: 	null,
	userStatus: "?", 		//	"ANONYMOUS", "LOGGING", "FETCHING", "LOGGED"
	users:	null,
	notes:	null,
	saison: null,
	saisonToFetch: null,
	saisons: null,
};


export default function appReducer(state = initialState, action) {


    switch (action.type) {



		case "DISPATCH_GLOBAL_FETCH":{
			const result = action.payload

			if( !result.user || result.user === 'notFetched'){
				console.log("DISPATCH_GLOBAL_FETCH_ERROR : user not fetched")
				return state
			}
			if( !result.users || result.users === 'notFetched'){
				console.log("DISPATCH_GLOBAL_FETCH_ERROR : users not fetched")
				return state
			}
			if( !result.notes || result.notes === 'notFetched'){
				console.log("DISPATCH_GLOBAL_FETCH_ERROR : notes not fetched")
				return state
			}
			if( !result.saison || result.saison === 'notFetched'){
				console.log("DISPATCH_GLOBAL_FETCH_ERROR : saison not fetched")
				return state
			}
			if( !result.saisons || result.saisons === 'notFetched'){
				console.log("DISPATCH_GLOBAL_FETCH_ERROR : saisons not fetched")
				return state
			}

			const newUser = userInitWithNotes(result.user, result.notes)
			const newUsers = usersInitWithNotes(result.users, result.notes)
			console.log("newUser", newUser)
			//alert("aaaaaaaaaaaaaaaa")
			return { ...state,
				user: 		newUser,
				userStatus: "LOGGED",
				users:		newUsers,
				notes:		result.notes,
				saison:		 result.saison,
				//saisonToFetch: null,
				saisons: 	result.saisons,
			}
		}




		case "SET_USER_STATUS":	return { ...state, userStatus: 	action.payload }
		case "SET_USERS":		return { ...state, users: 		action.payload }

		case "SET_SAISON":		return { ...state, saison: 		action.payload }
		case "SET_SAISON_TO_FETCH":		return { ...state, saisonToFetch: 		action.payload }
		case "SET_SAISONS":		return { ...state, saisons: 	action.payload }


		case "SET_NOTES":{
			initUsersWithNotes(action.payload)
			return { ...state, notes: 		action.payload }
		}

		case "SET_USER":{
			return { ...state,
				user: action.payload,
				userStatus: "LOGGED"
			}
		}

		case "LOG_IN":{
			//console.log("action.payload.apiToken")
			//console.log(action.payload.apiToken)
			localStorage.setItem("userToken", action.payload.token)
			return { ...state,
				userStatus:		"LOGGED",
				user: action.payload,
			}
		}

		case "LOG_OUT":{
			localStorage.removeItem("userToken")
			return { ...state,
				userStatus:		"ANONYMOUS",
				user: null,
			}
		}

		case "CREATE_NOTE":{
			console.log("appReducer.CREATE_NOTE")
			console.log("action.payload", action.payload)
			const newNotes = state.notes.concat(action.payload)
			newNotes.sort((a,b) => (a.dateHeure < b.dateHeure) ? 1 : ((b.dateHeure < a.dateHeure) ? -1 : 0))
			initUsersWithNotes(newNotes)
			return { ...state, notes: newNotes }
		}

		/*
		case "DELETE_ARTICLE":{
			let newArticles = state.articles.filter(oldArticle => oldArticle.id !==  action.payload)
			return { ...state, articles: newArticles }
		}


		case "UPDATE_ARTICLE":{
			const newArticles = state.articles.map(art => (art.id === action.payload.id ? action.payload : art))
			return { ...state, articles: 		newArticles }
		}
		*/


        default:					return state

    }






	function userInitWithNotes(user, notes){

		user.scoreTotal = 0
		user.scoreTotal =  notes.filter(n=> n.userReceveur.id===user.id ).reduce((partial_sum, nte) => partial_sum + nte.points,0)

		const notesDonneesAujourdhui =  notes.filter(n=> n.userDonneur.id===user.id && isToday(new Date(n.dateHeure)))
		if (notesDonneesAujourdhui.length>0){
			user.pointsDonnesAujourdhui = notesDonneesAujourdhui.reduce((partial_sum, nte) => partial_sum + Math.abs(nte.points),0)
		}
		else{
			user.pointsDonnesAujourdhui = 0
		}
		return user
	}



	function usersInitWithNotes(users, notes){

		users.map((user)=>(
			user.scoreTotal =  notes.filter(n=> n.userReceveur.id===user.id ).reduce((partial_sum, nte) => partial_sum + nte.points,0)
		))
		users.map((user)=>(
			user.pointsDonnesAujourdhui =  notes.filter(n=> n.userDonneur.id===user.id && isToday(new Date(n.dateHeure)) ).reduce((partial_sum, nte) => partial_sum + Math.abs(nte.points),0)
		))
		return users

	}

	function initUsersWithNotes(notes){

		// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! IMMMMMMMMUTTTTTTTTABBBBBBBBBIIIIIIILLLLLLLLLLLLLIIIIIIIIIIITYYYYYYYYYY

		state.user.scoreTotal =  notes.filter(n=> n.userReceveur.id===state.user.id ).reduce((partial_sum, nte) => partial_sum + nte.points,0)
		state.user.pointsDonnesAujourdhui =  notes.filter(n=> n.userDonneur.id===state.user.id && isToday(new Date(n.dateHeure)) ).reduce((partial_sum, nte) => partial_sum + Math.abs(nte.points),0)


		state.users.map((user)=>(
			user.scoreTotal =  notes.filter(n=> n.userReceveur.id===user.id ).reduce((partial_sum, nte) => partial_sum + nte.points,0)
		))
		state.users.map((user)=>(
			user.pointsDonnesAujourdhui =  notes.filter(n=> n.userDonneur.id===user.id && isToday(new Date(n.dateHeure)) ).reduce((partial_sum, nte) => partial_sum + Math.abs(nte.points),0)
		))

	}



	function isToday(someDate){
	  const today = new Date()
	  return someDate.getDate() === today.getDate() &&
	    someDate.getMonth() === today.getMonth() &&
	    someDate.getFullYear() === today.getFullYear()
	}

};
