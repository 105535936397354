/*
*	FormPassword
*	- Modifier le mot de passe d'un utilisateur :
*		-
*		-
*		-
*	- Imported in :
*		- FriendShow
*/
//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS

import React from 'react';
import { useState } from "react";
import {  useSelector,   useDispatch  } from "react-redux";
import {useEffect}  from "react";
import Button from 'react-bootstrap/Button';
//■■■■■■■■■■■■■■■■■■■■ HELPERS
import { apiEditUserPassword} from 'api/api'
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import ZxModal 			from 'components/_composants/ZxModal'
import ZxForm			from 'components/_composants/ZxForm'
import ZxFormInput		from 'components/_composants/ZxFormInput'
//■■■■■■■■■■■■■■■■■■■■ STYLES

//██████████████████████████████████████████████████████████████████████████████


export default function FormPassword({formInitialValues, handleCloseForm, friend}) {


	const [form, setForm] = useState(formInitialValues)
	const [errors, setErrors] = useState({oldPassword:null, newPassword1: null, newPassword2: null, mismatch:null})
	const [formIsValid, setFormIsValid] = useState(false)

	const [isLoading, setIsLoading] = useState(false);
  	const dispatch = useDispatch();




	useEffect(() => {
		setErrors({
			oldPassword:  (form.oldPassword === ""  ? "Saisissez le mot de passe admin": null),
			newPassword1: (form.newPassword1 === "" ? "Saisissez le nouveau mot de passe": null),
			newPassword2: (form.newPassword2 === "" ? "Re-saisissez le nouveau mot de passe": null),
			mismatch: (form.newPassword1 !== form.newPassword2 && form.newPassword1 !== "" && form.newPassword2 !== "")
		})
		setFormIsValid((
			form.oldPassword !== "" &&
			form.newPassword1 !== "" &&
			form.newPassword2 !== "" &&
			form.newPassword1 === form.newPassword2))

	}, [form]);

	const handleFormChange = (event) => {
  		const { name, value } = event.target
  		const updatedForm = {...form,    [name]: value  }
  		//console.log('Form changed: ', updatedForm);
  		setForm(updatedForm)
	}



	function handleSubmitCreate(){
		if(!formIsValid){
			return null
		}
		//alert("Todo : handleSubmitCreate")
		//console.log(form)
		setIsLoading(true)
		apiEditUserPassword(friend.id, form.oldPassword, form.newPassword1)
			.then(
				(result) => {

					if(result.reponse && result.reponse === "OK"){
						alert("Modif OK")
						setIsLoading(false)
						handleCloseForm()
					}
					else if(result.apixError ){
						alert(result.apixError)
						setIsLoading(false)
					}
					else{
						alert("error (check log)")
						console.log(result)
						setIsLoading(false)
					}

				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)
	}

	function handleSubmitUpdate(){	alert("UNUSED")		}
	function handleDelete(){		alert("UNUSED")		}


    return (


		<ZxModal	styles={{ width: "400px" }}		>

		<ZxForm
				form={form}
				handleCloseForm={handleCloseForm}
				handleDelete={handleDelete}
				handleSubmitCreate={handleSubmitCreate}
				handleSubmitUpdate={handleSubmitUpdate}
				handleFormChange={handleFormChange}
				createTitle={<>Modifier le mot de passe de <b>{friend.username}</b></>}
				updateTitle="!!!!!!Modifier..........."
				createButtonText="Modifier le mot de passe"
				showDeleteButton={true}
				isLoading={isLoading}
				formIsValid={formIsValid}
			>

				<ZxFormInput 	label="Mot de passe admin" 		name="oldPassword" 		error={errors.oldPassword}	type="password"	/>
				<ZxFormInput 	label="Nouveau mot de passe" 	name="newPassword1"  	error={errors.newPassword1}	type="password"	/>
				<ZxFormInput 	label="Nouveau mot de passe" 	name="newPassword2"  	error={errors.newPassword2}	type="password"	/>

				{errors.mismatch &&
					<div className="formError col-12 text-danger">Les 2 nouveaux mots de passe sont differents</div>
				}

			</ZxForm>


		</ZxModal>
    )
  }
