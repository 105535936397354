// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useDispatch } from "react-redux"
import { useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
import { apiLogin } from './apiLogin'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading 			from '../_nav/Loading'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■


// █████████████████████████████████████████████████████████████████████████████


export default function FormLogin() {


	const dispatch = useDispatch();

	const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)


    function validateForm() {
        return username.length > 0 && password.length > 0
    }

    function handleSubmit(event) {

        event.preventDefault()
		setIsLoading(true)
		setError(null)
		//dispatch({ type: "SET_USER_STATUS", payload: "LOGGING" })

		dispatch({ type: "SET_USER_STATUS", payload: "LOGGING" })
		apiLogin(username, password).then(
			(reponse) =>	{
				if(reponse.user){
					//console.log(reponse.user)
					dispatch({ type: "LOG_IN", payload: reponse.user })
				}
				if(reponse.error){
					let strError = reponse.error

					if (strError === "E0011003_USERNAME_NOT_FOUND"){
						strError = "Nom d'utilisateur introuvable"
					}
					else if (strError === "E0011004_WRONG_PASSWORD"){
						strError = "Mot de passe incorrect"
					}

					setError(strError)
				}
				setIsLoading(false)
			}
		)

    }



    return (
	    <Form onSubmit={handleSubmit}>

	        <Form.Group size="lg" controlId="email">
	            <Form.Label>Nom d'utilisateur</Form.Label>
	            <Form.Control
	                autoFocus
	                type="username"
	                value={username}
	                onChange={(e) => setUsername(e.target.value)}
	            />
	        </Form.Group>

	        <Form.Group size="lg" controlId="password" className="mt-2 mb-4">
	            <Form.Label>Mot de passe</Form.Label>
	            <Form.Control
	                type="password"
	                value={password}
					autoComplete="on"
	                onChange={(e) => setPassword(e.target.value)}
	            />
	        </Form.Group>

			{isLoading ?
				<Loading message="Connexion"/>
			:
	            <Button
	                block
	                size="lg"
	                type="submit"
	                disabled={!validateForm()}
	            >
	                Connexion
	            </Button>
			}


			{error &&
				<div className="text-danger mt-3">
					Une erreur s'est produite:<br />
					<i>{error}</i>
				</div>
			}

		</Form>
    )
}
