// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
//import { NavLink } from "react-router-dom";
//import { Link } from "react-scroll";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container, NavDropdown, Nav /*, Dropdown*/ } from "react-bootstrap";
//import {useEffect}  from "react";
import { useSelector,  useDispatch  } from "react-redux";
import { FaUserFriends, FaHome, FaRoute, FaSignOutAlt, FaMap} from 'react-icons/fa';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import './stylesNavHeader.scss'




// █████████████████████████████████████████████████████████████████████████████

export default function NavHeader() {
	//const app = useSelector((state) => state.app)
	const app = useSelector((state) => state.app)
	const dispatch = useDispatch();
	//console.log(app.user)

	function btLogoutClick(){
		dispatch({ type: "LOG_OUT" })
	}



	return (
		<Navbar  expand="lg" className="app-navbar navbar-expand-sm navbar-dark bg-primary bg-gradient">
		  	<Container  fluid>

			    <Navbar.Brand >
					<img
				        src="/img/app-logo/app-logo-32.png"
				        alt="RYF"
				    />
					<span className="app-title">
						RYF.zedixi.com
					</span>
	  			</Navbar.Brand>

			    <Navbar.Toggle aria-controls="basic-navbar-nav" />


				<Navbar.Collapse id="basic-navbar-nav">
				    <Nav className="me-auto container">




						<LinkContainer exact to="/"	  			><Nav.Link><FaHome /> Accueil</Nav.Link></LinkContainer>

						
						<LinkContainer exact to="/mindmap" 		><Nav.Link><FaMap /> Mindmap (admin)</Nav.Link></LinkContainer>
						{/*
						<LinkContainer exact to="/users" 	><Nav.Link><FaUserFriends /> Users</Nav.Link></LinkContainer>
						<LinkContainer exact to="/articles" ><Nav.Link><FaUserFriends /> Articles</Nav.Link></LinkContainer>

						<LinkContainer exact to="/"					><Nav.Link>Appels</Nav.Link></LinkContainer>
						<LinkContainer exact to="/fse"	  disabled 	><Nav.Link>FSE</Nav.Link></LinkContainer>
						*/}
						{false &&
					        <NavDropdown  title="Admin" id="basic-nav-dropdown" className="ms-auto" >
								<LinkContainer exact to="/admin/users" 	>
									<NavDropdown.Item><FaUserFriends /> Utilisateurs</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer exact to="/admin/articles" 	>
									<NavDropdown.Item>Articles</NavDropdown.Item>
								</LinkContainer>

					          	<NavDropdown.Divider />
					          	<NavDropdown.Item href="#action/3.4" disabled><FaRoute /> Routes</NavDropdown.Item>
					       	</NavDropdown>
						}

						{app.user &&
					        <NavDropdown title={app.user.username} id="user-basic-nav-dropdown" className="ms-auto" >
					          	<NavDropdown.Item onClick={()=>btLogoutClick()}><FaSignOutAlt /> Déconnexion</NavDropdown.Item>
					          	<NavDropdown.Item href="#action/3.3" disabled><FaSignOutAlt /> Déconnecter de tous les appareils</NavDropdown.Item>
					          	<NavDropdown.Divider />
					          	<NavDropdown.Item href="#action/3.3" disabled>Mon profil</NavDropdown.Item>
					       	</NavDropdown>
						}

				    </Nav>
			    </Navbar.Collapse>


		  	</Container>
		</Navbar>
	)

}
