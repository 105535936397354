//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
import { useState } from 'react'
//import {useEffect}  from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
//import Button from 'react-bootstrap/Button';
//import { FaPlus, FaSortAlphaDown } from 'react-icons/fa';
//import styled from 'styled-components'

//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import FriendItem from './FriendItem'
import FriendsListeFilter 	from './FriendsListeFilter'
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



export default function FriendsListe({titre=null}) {

	const [sortBy, setSortBy]=useState("PSEUDO")


	const app = useSelector((state) => state.app)


	sortFriends()

	function sortFriends(){
		if(sortBy==="PSEUDO"){
			app.users.sort((a,b) => (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0))
		}
		else{
			app.users.sort((a,b) => (a.scoreTotal > b.scoreTotal) ? -1 : ((b.scoreTotal > a.scoreTotal) ? 1 : 0))
		}


	}



    return (
        <div className="xxxxxxx">

			{titre && <h5>{titre}</h5>}


			<FriendsListeFilter sortBy={sortBy}  setSortBy={setSortBy} />

			<div className="row col-12">
				{app.users.map((frd)=>(
					<FriendItem key={"fr"+frd.id} friend={frd}/>
				))}
			</div>

        </div>
    )
}
