//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import { useContext } from 'react';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS :
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import { FormContext } from './ZxForm';
//■■■■■■■■■■■■■■■■■■■■ CSS
//██████████████████████████████████████████████████████████████████████████████



export default function ZxFormInput({ label, type='text', name, error=null }) {

  	const formContext = useContext(FormContext);
  	const { form, handleFormChange } = formContext;

  	return (
		<div className="col-12 mb-3">
			<label className="col-12 zxFormInput">
				<div className="col-12">
					{label}
					<input
						key={"input-"+name}
		        		type={type}
		        		name={name}
		        		value={form[name]}
		        		onChange={handleFormChange}
						className="float-end"
		      		/>
				</div>

			</label>
			{error &&
				<div className="formError col-12 text-danger">{error}</div>
			}
		</div>
  	)
}
