// https://dev.to/trishathecookie/react-creating-a-reusable-form-using-react-context-5eof

//█████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■	LIBS
import React from "react";
import Button from 'react-bootstrap/Button';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■	HELPERS
import {theme} from '../../styles/theme'
//■■■■■■■■■■■■■■■■■■■■	COMPONENTS
import Loading from '../_nav/Loading'
//■■■■■■■■■■■■■■■■■■■■	CSS
//█████████████████████████████████████████████████████████████████████████████


export const FormContext = React.createContext({	form: {}	});


export default function ZxForm({
		children,
		form,
		handleFormChange, handleCloseForm, handleDelete, handleSubmitCreate, handleSubmitUpdate,
		createTitle, updateTitle, createButtonText = "Ajouter",
		showDeleteButton=true,
		isLoading,
		formIsValid=true
	})  {

	const editMode = form.id ? true : false


  	return (
    	<StyledForm>

		    <StyledHeader className="zxFormHeader col-12">
				<span className="zxFormTitle">
					{editMode ? updateTitle : createTitle}
				</span>
				{editMode && !isLoading && <ButtonDelete action={handleDelete}/> }
		    </StyledHeader>

	      	<FormContext.Provider value={{ form, handleFormChange }}>
	        	{children}
	      	</FormContext.Provider>


	    	<StyledFooter>
				{isLoading ?
					<Loading />
				:
					<>
						{editMode ?
							<ButtonUpdate action={handleSubmitUpdate} formIsValid={formIsValid}/>
							:
							<ButtonCreate action={handleSubmitCreate} createButtonText={createButtonText} formIsValid={formIsValid}/>
						}
						<ButtonCancel action={handleCloseForm}/>
					</>
				}
	    	</StyledFooter>

    	</StyledForm>
  	)

}





const StyledForm = styled.form`
  	background-color: white;
	width: 100%;
`;

const StyledHeader = styled.div`
  	/*background-color: yellow;*/
	width: 100%;
	margin-bottom:20px;
	padding-bottom:5px;
	border-bottom: 1px solid ${theme.colors.c1d3};
	.zxFormTitle{
		font-size: 1.3em;
		color: ${theme.colors.c1d3};
	}
`;

const StyledFooter = styled.div`
  	/*background-color: pink;*/
	width: 100%;
	margin-top:20px;
	padding-top:10px;
	border-top: 1px solid ${theme.colors.c1d3};
	.zxFormTitle{
		font-size: 1.3em;
	}
`;







function ButtonUpdate({ action, formIsValid }) {
  	return (
		<Button
			className="btn-success"
			onClick={action}
			tabIndex="0"
		>
			Modifier
		</Button>
  	)
}

function ButtonCreate({ action, createButtonText, formIsValid }) {
  	return (
		<Button
			variant={formIsValid ? "success" : "secondary"}
			onClick={action}
			tabIndex="0"
		>
			{createButtonText}
		</Button>
  	)
}


function ButtonCancel({ action }) {
  	return (
		<Button
			className="btn-danger float-end"
			onClick={action}
			tabIndex="0"
		>
			Annuler
		</Button>
  	)
}


function ButtonDelete({ action }) {
  	return (
		<Button
			className="btn-danger btn-xs float-end"
			onClick={action}
			tabIndex="0"
			>
			Supprimer
		</Button>
  	)
}
