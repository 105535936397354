// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import FormLogin 			from './FormLogin'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import "./stylesLogin.scss";


// █████████████████████████████████████████████████████████████████████████████

export default function Login() {


    return (
        <div className="pageLogin">

			<h1 className="app-title mb-4">
				RYF.zedixi.com
			</h1>

			<img
				className="app-logo mb-4"
				alt="RYF"
				src="/img/app-logo/app-logo-512.png"
			/>

			<FormLogin />

	    </div>
    )

}
