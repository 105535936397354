// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
import { useState } from 'react'
import {useEffect}  from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaBan } from 'react-icons/fa';


// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading 		from '../_nav/Loading'
import TableUsers 		from './userIndex/TableUsers'
import FormUser 		from './FormUser'

//////////////////import FormUser from './FormUser'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■

import "./userIndex/stylesUserIndex.scss"


// █████████████████████████████████████████████████████████████████████████████
export default function UserIndex({globalFetch}) {

	const rUser = useSelector((state) => state.rUser)


	const [selectedUser, setSelectedUser] = useState(null)

    useEffect(() => {
        if (  rUser.users===null    ){
			globalFetch({
				fetchUser: (rUser.user ? false : true),
				fetchUsers: true,
			})
    	}
    }, [])


	function handleAddUserClick(){
		const newUser={
			id:null,
			nom:"",
			prenom:"",
			classe:"",
			regime:"",
			prioLundi:"",
			prioMardi:"",
			prioMercredi:"",
			prioJeudi:"",
			prioVendredi:"",
			actif: true,
			commentaire: "",
			numeroCarte: "",
		}
		setSelectedUser(newUser)
	}


//	console.log(app)
    return (
        <div className="app-page row col-12 pageDpListe">

			{selectedUser !== null  &&
				<FormUser
					user={selectedUser}
					setUser={setSelectedUser}
					etabId={10}
				/>
			}


            <div className="page-header row col-12">
                <div className="page-title col-12">
					<FaBan /> Admin: Gestion des utilisateurs
				</div>
            </div>

            <div className="page-content row col-12">

				<div className="row col-12">

					<div className="col-6 legendes mb-5">
						<Button
							className="btn-primary float-right"
							onClick={() => handleAddUserClick()}
							title="Ajouter un élève"
						>
							<FaPlus /> Ajouter un utilisateur
						</Button>
					</div>
				</div>


				{rUser.users ?
					<TableUsers
						users={rUser.users}
						setSelectedUser={setSelectedUser}
					/>
					:
					<Loading message="Chargement des utilisateurs..." />
				}

			</div>

 		</div>

    )



}
