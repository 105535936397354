// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
//import { useState } from "react";
//import { useEffect } from "react";
//import { useSelector /*  ,  useDispatch*/ } from "react-redux";
//import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
//import 'moment/locale/fr';
//import { NavLink } from "react-router-dom";
//import Button from 'react-bootstrap/Button';
import { FaEdit } from 'react-icons/fa';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■

//import { diffMoments } from '../../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■


// █████████████████████████████████████████████████████████████████████████████

export default function UserTr({user, setSelectedUser}) {

	/*
	function handleEditAppelClick(appel){
		appel.date = Moment(appel.dateAppel).format("YYYY-MM-DD")
		setSelectedAppel(appel)
	}


	let trClassName = (diffDays===0 ? " tr-today " : "")

	trClassName += (numSemaine%2 ===0) ? " semaine-paire " : " semaine-impaire "
	*/

	let trClassName = "  "

	if(!user.actif){
		trClassName += " tr-inactif "
	}

	if(user.regime !== "DP"){
		trClassName += " tr-externe "
	}

	if(user.classe==="AED"){
		trClassName += " tr-aed "
	}


    return (
		<tr className={trClassName}>

			<td
				className="btn-warning text-center"
				onClick={() => setSelectedUser(user)}
				title="Modifier cet élève"
			>
				<FaEdit />
		   	</td>

		   	<td ><b>{user.pseudo}</b> {user.prenom} </td>

		   	<td className="text-center">{user.classe}</td>

		   	<td className="text-center">
		   		{!user.actif ?
					   "Inactif"
					   :
					   user.regime
				}
		   	</td>

			{/*
		   	<td>{user.prioLundi}</td>
		   	<td>{user.prioMardi}</td>
		   	<td>{user.prioJeudi}</td>
		   	<td>{user.prioVendredi}</td>
			*/}

   		   	<td className="joursPrio">
				{user.prioLundi 	&& <span><b>Lundi:</b> {user.prioLundi}</span>}
				{user.prioMardi 	&& <span><b>Mardi:</b> {user.prioMardi}</span>}
				{user.prioMercredi && <span><b>Mercredi:</b> {user.prioMercredi}</span>}
				{user.prioJeudi 	&& <span><b>Jeudi:</b> {user.prioJeudi}</span>}
				{user.prioVendredi && <span><b>Vendredi:</b> {user.prioVendredi}</span>}
   			</td>

   		   	<td>
				{user.numeroCarte}
   			</td>

   		   	<td>
				{user.commentaire}
   			</td>

		</tr>
    )


}
