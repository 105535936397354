//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
//import { useState } from 'react'
//import {useEffect}  from "react";
//import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
//import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';
import styled from 'styled-components'
import { NavLink } from "react-router-dom";
//■■■■■■■■■■■■■■■■■■■■ HELPERS
import {theme} from '../../styles/theme'
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



export default function NoteItem({note}) {
	let dd = new Date(note.dateHeure)
	//let dd = Date.parse("2020-01-01")
	//console.log(dd + " --- " + dd.toString()  + " ------- " + typeof(dd))
	 //---- {dd.getFullYear()}
    return (
        <StyledNoteItem className="row col-12"  points={note.points}>

			<div className="col-12 col-lg-3">

				<NavLink className="userDonneur" to={"/friend/"+note.userDonneur.id}>{note.userDonneur.username}</NavLink>
				<FaArrowRight />
				<NavLink className="userReceveur" to={"/friend/"+note.userReceveur.id}>{note.userReceveur.username}</NavLink>

				<span className="date float-end">{dd.toLocaleString("fr-FR", {day: '2-digit', month: 'short', year:"numeric", hour: '2-digit',minute: '2-digit'}) }</span>
			</div>



			<div className="row content col-12 col-lg-9">
				<div className="points col-1">{note.points}</div>
				<div className="motif col-11">{note.motif}</div>
			</div>


		{/*
			<div className="header col-12">
				<NavLink className="sender" to={"/friend/"+note.userDonneur.id}>{note.userDonneur.username}</NavLink>
				<FaArrowRight />
				<NavLink className="receiver" to={"/friend/"+note.userReceveur.id}>{note.userReceveur.username}</NavLink>
				<span className="date float-end">{note.dateHeure}</span>
			</div>

			<div className="content col-12">
				<div className="points">{note.points}</div>
				<span className="motif">"{note.motif}"</span>
			</div>
			*/}
        </StyledNoteItem>
    )
}

const StyledNoteItem = styled.div`
	padding:5px;
	border: 1px solid ${theme.colors.c1};
	margin-bottom:5px;
	text-decoration:none;
	color:black;


	.userDonneur, .userReceveur{
		color: ${theme.colors.c1};
		font-size:0.9em;
		font-weight:bold;
		&.sender{ padding-right: 5px;}
		&.receiver{ padding-left: 5px;}
	}



	.content{
		/*background-color:cyan;*/
	}

	.date{
		font-size:0.8em;
	}

	.points{
		font-size:2em;
		text-align: right;
		color: ${props => (props.points > 0) ? "green" : "red" };
		/*background-color:cyan;*/

	}
`;
